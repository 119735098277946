import {helper} from "@/services/Helper";
import store from "@/store";

const menus =[
    {
        "name": "configuration-index",
        "path": "/configuration/branch/list",
        "label": "Configuration",
        "roles": [
            [
                {
                    "code": "ROLE_PRODUCTEUR"
                }
            ]
        ],
        "icon": "fad fa-gears",
        "subMenus": [
            {
                "name": "configuration-index",
                "icon": "fad fa-folder-tree",
                "path": "/configuration/branch/list",
                "label": "Branches",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "data-type-manager",
                "icon": "fad fa-input-numeric",
                "path": "/configuration/data/type",
                "label": "Type de donn\u00e9es",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "avenant-type-manager",
                "icon": "fad fa-file-signature",
                "path": "/configuration/avenant/type",
                "label": "Avenants",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "tag-manager",
                "icon": "fad fa-tags",
                "path": "/configuration/tag",
                "label": "Tags",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "reason-manager",
                "icon": "fad fa-question",
                "path": "/configuration/reason",
                "label": "Motifs",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "duration-manager",
                "icon": "fad fa-stopwatch-20",
                "path": "/configuration/duration",
                "label": "Gestion de dur\u00e9es ",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "orphan-form-list",
                "icon": "fad fa-ballot-check",
                "path": "/configuration/:slug?/survey/list",
                "label": "Formulaires",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "insurer-list",
                "icon": "fad fa-building-shield",
                "path": "/configuration/insurer/list",
                "label": "Assureurs",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "pack-category-list",
                "icon": "fad fa-boxes-stacked",
                "path": "/configuration/pack-category/list",
                "label": "Categories de pack",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "language-list",
                "icon": "fad fa-language",
                "path": "/configuration/language/list",
                "label": "Langues",
                "roles": [
                    [
                        {
                            "code": "ROLE_MANAGER"
                        }
                    ]
                ]
            },
            {
                "name": "parameters",
                "icon": "fad fa-cogs",
                "path": "/configuration/parameters",
                "label": "Parametres",
                "roles": [
                    [
                        {
                            "code": "ROLE_MANAGER"
                        }
                    ]
                ]
            }
        ]
    },
    {
        "name": "income-index",
        "path": "/income",
        "label": "Production",
        "roles": [
            [
                {
                    "code": "ROLE_PARTNER"
                }
            ],
            [
                {
                    "code": "ROLE_PRODUCTEUR"
                }
            ],
            [
                {
                    "code": "ROLE_COMMERCIAL"
                }
            ]
        ],
        "icon": "fad fa-bag-shopping",
        "subMenus": [
            {
                "name": "income-index",
                "icon": "fad fa-ballot-check",
                "path": "/income",
                "label": "Dashboard",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "cotation-list",
                "icon": "fad fa-file-invoice-dollar",
                "path": "/income/cotation/list",
                "label": "Cotations",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "payment-list",
                "icon": "fad fa-money-bills-simple",
                "path": "/income/payments",
                "label": "Liste de paiements",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "delivery-list",
                "icon": "fad fa-person-biking-mountain",
                "path": "/income/delivery",
                "label": "LIste des livraisons",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ]
                ]
            },
            {
                "name": "contract-list",
                "icon": "fad fa-file-invoice",
                "path": "/income/contract/list",
                "label": "Contrats",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "customer-list",
                "icon": "fad fa-user-vneck",
                "path": "/income/customer/list",
                "label": "Clients",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "partner-list",
                "icon": "fad fa-user-group",
                "path": "/income/partner/list",
                "label": "Gestion des apporteurs",
                "roles": [
                    [
                        {
                            "code": "ROLE_PARTNER_MANAGER"
                        }
                    ]
                ]
            },
            {
                "name": "parrainage-list",
                "icon": "fad fa-user-group",
                "path": "/income/parrainage/list",
                "label": "Liste de parrainage",
                "roles": [
                    [
                        {
                            "code": "ROLE_ADMIN"
                        }
                    ]
                ]
            },
            {
                "name": "payment-method-list",
                "icon": "fad fa-money-bill-wave",
                "path": "/income/payment/method/list",
                "label": "Liste des m\u00e9thodes de paiements ",
                "roles": [
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PARTNER_MANAGER"
                        }
                    ]
                ]
            },
            {
                "name": "income-import",
                "icon": "fad fa-file-import",
                "path": "/income/import",
                "label": "Import des cotations",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "income-state",
                "icon": "fad fa-list",
                "path": "/income/state",
                "label": "Etats",
                "roles": [
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_PRODUCTEUR"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_COMPTABILITE"
                        }
                    ]
                ]
            }
        ]
    },
    {
        "name": "crm-index",
        "path": "/crm",
        "label": "CRM",
        "roles": [
            [
                {
                    "code": "ROLE_COMMERCIAL"
                }
            ]
        ],
        "icon": "fad fa-comments-dollar",
        "subMenus": [
            {
                "name": "handshake-list",
                "icon": "fad fa-phone-plus",
                "path": "/crm/handshake/list",
                "label": "Liste des prise de contact",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "prospection-clients",
                "icon": "fad fa-person",
                "path": "/crm/prospects",
                "label": "Liste des prospects",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "company",
                "icon": "fad fa-building-memo",
                "path": "/crm/entreprise",
                "label": "Entreprise",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "campaign-list",
                "icon": "fad fa-wave-square",
                "path": "/crm/campaign/list",
                "label": "Campagne",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "chat-whatsapp",
                "icon": "fab fa-whatsapp",
                "path": "/crm/chat-whatsapp",
                "label": "Whatsapp",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "sms",
                "icon": "fad fa-comment-sms",
                "path": "/crm/sms",
                "label": "SMS",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "email-template-list",
                "icon": "fad fa-at",
                "path": "/crm/email/template/list",
                "label": "Modele d'email",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "sms-template-list",
                "icon": "fad fa-cogs",
                "path": "/crm/template/sms/list",
                "label": "Modele d'SMS",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "extractor",
                "icon": "fad fa-inbox-out",
                "path": "/crm/extractor",
                "label": "Extracteur",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            },
            {
                "name": "url-shortener",
                "icon": "fad fa-cogs",
                "path": "/crm/url/shortener",
                "label": "R\u00e9ducteur d'URL",
                "roles": [
                    [
                        {
                            "code": "ROLE_COMMERCIAL"
                        }
                    ]
                ]
            }
        ]
    },
    {
        "name": "admin-index",
        "path": "/admin",
        "label": "Administration",
        "roles": [
            [
                {
                    "code": "ROLE_ADMIN"
                }
            ],
            [
                {
                    "code": "ROLE_MANAGER"
                }
            ]
        ],
        "icon": "fad fa-user-shield",
        "subMenus": [
            {
                "name": "admin-index",
                "icon": "fad fa-users",
                "path": "/admin",
                "label": "Utilisateurs",
                "roles": [
                    [
                        {
                            "code": "ROLE_ADMIN"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_MANAGER"
                        }
                    ]
                ]
            },
            {
                "name": "tenant-list",
                "icon": "fad fa-list-tree",
                "path": "/admin/tenant/list",
                "label": "Tenants",
                "roles": [
                    [
                        {
                            "code": "ROLE_ADMIN"
                        }
                    ]
                ]
            },
            {
                "name": "role-list",
                "icon": "fad fa-key-skeleton-left-right",
                "path": "/admin/role/list",
                "label": "Roles",
                "roles": [
                    [
                        {
                            "code": "ROLE_ADMIN"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_MANAGER"
                        }
                    ]
                ]
            },
            {
                "name": "menu-manager",
                "icon": "fad fa-sitemap",
                "path": "/admin/menu/manager",
                "label": "Menus",
                "roles": [
                    [
                        {
                            "code": "ROLE_ADMIN"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_MANAGER"
                        }
                    ]
                ]
            },
            {
                "name": "api-manager",
                "icon": "fad fa-server",
                "path": "/admin/api/manager",
                "label": "Api",
                "roles": [
                    [
                        {
                            "code": "ROLE_ADMIN"
                        }
                    ]
                ]
            }
        ]
    },
    {
        "name": "partner-admin-users",
        "path": "/partner/users",
        "label": "Administration",
        "roles": [
            [
                {
                    "code": "ROLE_PARTNER"
                }
            ]
        ],
        "icon": "fad fa-shield-check",
        "subMenus": [
            {
                "name": "partner-admin-index",
                "icon": "fad fa-stairs",
                "path": "/partner/hierarchy",
                "label": "Hi\u00e9rarchie",
                "roles": [
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "partner-agencies",
                "icon": "fad fa-building-memo",
                "path": "/partner/agences",
                "label": "Agences",
                "roles": [
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "partner-admin-users",
                "icon": "fad fa-user-group",
                "path": "/partner/users",
                "label": "Utilisateurs ",
                "roles": [
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ]
                ]
            },
            {
                "name": "login",
                "icon": "fad fa-cogs",
                "path": "/login",
                "label": "",
                "roles": [
                    [
                        {
                            "code": "ROLE_PUBLIC"
                        }
                    ]
                ]
            },
            {
                "name": "partner-login",
                "icon": "fad fa-cogs",
                "path": "/partner/login",
                "label": "",
                "roles": [
                    [
                        {
                            "code": "ROLE_PUBLIC"
                        }
                    ]
                ]
            },
            {
                "name": "manager-login",
                "icon": "fad fa-cogs",
                "path": "/manager/login",
                "label": "",
                "roles": [
                    [
                        {
                            "code": "ROLE_PUBLIC"
                        }
                    ]
                ]
            },
            {
                "name": "home",
                "icon": "fad fa-cogs",
                "path": "",
                "label": "",
                "roles": [
                    [
                        {
                            "code": "ROLE_PARTNER"
                        }
                    ],
                    [
                        {
                            "code": "ROLE_MANAGER"
                        }
                    ]
                ]
            }
        ]
    }
]

const routes :[] = []




export function getMenu(name: any) {
    const menuList = store.state.menus ?? menus
    for (let menu of menuList) {
        // console.log('menu ' + menu.name + '===' + name)
        if (menu.name === name && menu.roles && !helper.empty(menu.roles)) {
            return menu;
        }
        if (menu.subMenus) {
            for (let sub of menu.subMenus) {
                // console.log('sub ' + sub.name + '===' + name)
                if (sub.name === name) {
                    return sub
                }
            }
        }
        // else {
        //     console.log('no submenus')
        // }
    }

}



export default menus;
