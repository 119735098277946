<template>
  <div class="my-2 item">
<!--    <div class="fs-2 fw-bolder cursor-pointer" v-if="!itemType.isRoot">-->
<!--      <i class="fad fa-caret-right me-2 fs-2"></i>-->
<!--      {{ itemType.name }}-->
<!--    </div>-->
<!--    <div class="item-name fw-bolder text-muted">-->
<!--      <template v-if="item">-->
<!--        <div v-if="!empty(item.generatedAlias)">-->
<!--          {{item.generatedAlias}}-->
<!--        </div>-->
<!--        <div v-else-if="!empty(item.defaultAlias)">-->
<!--          {{item.defaultAlias}}-->
<!--        </div>-->
<!--        <div v-else-if="!empty(item.name)">-->
<!--          {{item.name}}-->
<!--        </div>-->
<!--        <div v-else-if="!empty(item.model.name)">-->
<!--          {{item.model.name}}-->
<!--        </div>-->
<!--      </template>-->
<!--    </div>-->
    <div class="ms-5" v-if="expand && itemType">
      <template v-for="property in itemType.properties">
        <AddRemovePropertyLine :model="property" :property="getPropertyByModel(property)" :itemTypes="itemTypes"
                               :key="property.id" v-if="property.type.code.includes('OBJECT')"/>
      </template>
    </div>
  </div>
</template>


<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import Item from "@/entity/Item";
import AddRemovePropertyLine from "@/views/Income/Avenant/AvenantManager/AddRemovePropertyLine.vue";
import Property from "@/entity/Property";
import ItemProperty from "@/entity/ItemProperty";
import ItemType from "@/entity/ItemType";
import {property} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

@Component({
  name: 'AddRemoveItemLine',
  methods: {property},
  components: {AddRemovePropertyLine}
})
export default class AddRemoveItemLine extends Vue {
  @Prop() item!: Item
  @Prop() itemType!: ItemType
  @Prop() itemTypes!: ItemType[]
  @Prop({default: 0}) level!: number
  expand = true
  hasObjectProperty = true

  mounted() {
    console.log('Item ' + this.itemType.name);
  }

  getPropertyByModel(model: ItemProperty) {
    if (this.item) {
      for (let p of this.item.properties) {
        if (p.model.uuid === model.uuid) {
          return p
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
