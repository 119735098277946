import {api} from "@/services/Api";
import Popup from "@/entity/Popup";

class LiveManager {


    engineSource: any = null

    listenEngineUpdate() {
        if (!this.engineSource) {
            const baseUrl = api.mercure;
            const hubUrl = new URL(baseUrl);
            hubUrl.searchParams.append('topic', '/engine');
            this.engineSource = new EventSource(hubUrl.toString());
            this.engineSource.onerror = (e:any)=>{
                this.engineSource.close()
                console.log("An error occurred while attempting to connect.");
            }
            this.engineSource.onmessage = (event: any) => {
                if (event.data) {
                    const data = JSON.parse(event.data)
                    if (data) {
                        if (data.branch && data.message) {
                            let color = data.color ? data.color : 'secondary';
                            new Popup(data.branch, data.message, color, 'fad fa-exclamation', true);
                        }
                    }
                }
            }
            // this.engineSource.addEventListener('error', (e:any) => {
            //     console.log("An error occurred while attempting to connect.");
            //     this.engineSource.close()
            // });
        }
    }

}


export const liveManager = new LiveManager()
