<template>
  <div class="d-flex align-items-stretch" id="kt_header_nav">
    <div class="header-menu align-items-stretch" data-kt-drawer="true"
         data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-direction="end"
         data-kt-drawer-name="header-menu" data-kt-drawer-overlay="true"
         data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
         data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-place="true" data-kt-place-mode="prepend"
         data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
      <div
          class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
          data-kt-menu="true" id="#kt_header_menu">


        <template v-for="menu in store.state.menus">
          <router-link :to="{name : menu.name}" class="menu-item me-lg-1" v-if="isMenuAllowed(menu)">
            <div class="menu-link" :class="{ 'active': isMenuSelected(menu) }">
              <div class="menu-title">
                <i :class="menu.icon + ' fs-2 me-2'" v-if="menu.icon"></i>
                <i class="fad fa-cogs  fs-2 me-2" v-else></i>
                <span>
                  {{ menu.label ? trans(menu.label) : menu.name }}
                </span>
              </div>
            </div>
          </router-link>
        </template>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import {auth} from "@/services/Auth";
import menus from "@/router/Menu";
import store from "@/store";

@Component({
  computed: {
    store() {
      return store
    }
  },
})
export default class LeftMenu extends Vue {
  public website = process.env.VUE_APP_BACKEND_URL;

  setPlatform(platform: string) {
    localStorage.setItem('platform', platform)
    this.$store.state.platform = platform
  }

  isTopPartner() {
    // if (auth.hasRole('ROLE_PARTNER')) {
    // console.log(this.$store.state.partner)
    if (this.$store.state.partner) {
      if (!this.$store.state.partner.parent) {
        return true
      }
    }
    // }
    return false
  }

  isMenuAllowed(menu: any) {
    // return true
    return auth.checkRoleTabs(menu.roles)
  }

  isMenuSelected(menu: any) {
    if (menu && menu.path) {
      const path = menu.path.replace(/^\/(.*)/, "$1");
      const currentPath = this.$route.path.replace(/^\/(.*)/, "$1")
      let root = path.split('/')[0]
      let currentRoot = currentPath.split('/')[0]
      return currentRoot === root
    }
    return false
  }

  pathIncludes(name: string) {
    return this.$router.currentRoute.path.includes(name)
  }


  get isConnected(): boolean {
    return api.isConnected();
  }

  mounted(){
    const menus = this.$store.getters.menus()
  }
}
</script>
<style scoped>

</style>
