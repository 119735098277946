<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'crm-index' }" class="text-muted text-hover-primary">
                  <i class="fad fa-comments"></i>
                  {{ trans("Communication") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>

        <div class="d-flex align-items-center py-1">
          <div class="btn btn-primary btn-sm m-2" @click="save">
            {{ trans(" Enregistrer l'avenant") }}
          </div>
        </div>
      </div>
    </div>
  </template>

  <template #content>
    <div class="row gx-5">
      <div class="fs-2x fw-bolder">
        {{ trans("Etat actuel du contrat") }}
      </div>
      <div class="col-9">

        <b-card class="my-5">
          <div>
            <ItemManager :item="avenant" v-if="avenant" />
          </div>
        </b-card>
        <div class="p-10 border-dashed">
          <h2 class="my-3">Structure</h2>
          <Loading v-if="loading" />
          <ItemTree :item="root" :itemTypes="itemTypes" v-if="root" />
          <!--        <AddRemoveItemLine :item="root" v-if="root" :level="0" :itemTypes="itemTypes" :itemType="root.model"/>-->
        </div>
      </div>

      <div class="col-3">

        <div class="card my-3">
          <template>
            <div class="card-body p-0">
              <div class="text-center px-5 pt-5">
                <div v-if="avenantInsurer">
                  <div style="max-width:90%;max-height:50px;margin:0 auto" v-if="avenantInsurer.photo">
                    <img :src="uploadRoot + avenantInsurer.photo" alt="" class="w-100 h-100 object-contain"
                      style="max-height: 50px">
                  </div>
                </div>
                <div v-if="avenantInsurer" class="pt-2 fw-bolder text-gray-400">
                  {{ avenantInsurer.label }}
                </div>

                <!--                                    =>{{result}}<<-->
                <div class="text-primary fw-bolder">
                  <!-- <div :key="result.uuid" class="fs-3x" v-if="result">
                    {{ helper.currencyFormat(finalPremium) }}
                  </div> -->

                  <div :key="result.uuid" class="fs-3x d-flex align-items-center" v-if="result">
                    <transition mode="out-in" name="slide-left">
                      <input type="number" class="form-control text-end" v-if="editPremium" v-model="finalPremium" />
                      <div class="fw-bolder cotation-detail flex-grow-1" v-else>
                        {{ helper.currencyFormat(finalPremium) }}
                      </div>
                    </transition>

                    <div class="btn btn-success btn-icon btn-sm ms-5" @click="editPremium = false" v-if="editPremium">
                      <i class="fad fa-save"></i>
                    </div>
                    <div class="btn btn-primary btn-icon btn-sm ms-5" @click="editPremium = true" v-else>
                      <i class="fad fa-edit"></i>
                    </div>
                  </div>


                  <div>
                    {{ getDuree() }}
                  </div>
                </div>

                <div class="text-primary fw-bolder d-flex flex-wrap justify-content-center align-items-center">
                  <div class="" v-if="start">Du {{ readableDate(start, 'dmY') }}</div>
                  <div class="ms-2" v-if="end">Au {{ readableDate(end, 'dmY') }}</div>
                  <div class="ms-2" v-if="dureeEnJour()">({{ dureeEnJour() }}
                    <template v-if="dureeEnJour() > 1">jours</template>
                    <template v-else>jour</template>
                    )
                  </div>
                </div>

              </div>







              <div class="py-5">
                <div class="mh-500px overflow-auto">
                  <div class="fw-bolder p-5 fs-2">
                    Détails de la facture
                  </div>
                  <table class="table table-striped gs-5 gy-2">
                    <thead></thead>
                    <tbody>
                      <template v-for="detail in premiumDetailModels">
                        <tr>
                          <td>
                            {{ detail.label }}
                          </td>
                          <td>
                            {{ detail.operator }}
                          </td>
                          <td class="text-end">
                            <div class="d-flex align-items-center" :set="premiumDetail = getDetail(detail)">
                              <transition mode="out-in" name="slide-left">
                                <input type="number" class="form-control text-end" :key="'input_' + detail.id"
                                  v-if="detail.edit || !getDetail(detail)" v-model="detail.value" />
                                <div
                                  :key="premiumDetail.detail ? helper.slugify(premiumDetail.detail.id) : helper.generateId()"
                                  class="fw-bolder cotation-detail flex-grow-1" v-else-if="premiumDetail">
                                  <!-- {{ premiumDetail }} -->
                                  {{ helper.currencyFormat(premiumDetail.premium, false, false) }}
                                </div>
                              </transition>
                              <label class="btn btn-icon btn-sm btn-warning ms-5" v-if="premiumDetail && !detail.edit"
                                @click="editDetail(detail)">
                                <input type="checkbox" v-model="detail.edit" class="hidden" />
                                <i class="fad fa-edit"></i>
                              </label>
                              <label class="btn btn-icon btn-sm btn-success ms-5" v-else @click="saveDetail(detail)"
                                :key="'save_' + detail.id">
                                <input type="checkbox" v-model="detail.edit" class="hidden">
                                <i class="fad fa-save"></i>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </template>

                      <!-- <template v-if="result">
                        <template v-for="detail in premiumDetails">
                            <tr :key="helper.slugify(detail.model.label)">
                                <td>
                                    {{ detail.model.label }}
                                </td>
                                <td>
                                    {{ detail.model.operator }}
                                </td>
                                <td class="text-end">
                                    <transition mode="out-in" name="slide-left">
                                        <div :key="detail.value ? helper.slugify(detail.value) : helper.generateId()"
                                            class="fw-bolder cotation-detail">
                                            {{
                                                helper.currencyFormat(detail.premium, false,
                                                    false)
                                            }}
                                        </div>
                                    </transition>
                                </td>
                            </tr>
                        </template>
                    </template> -->
                    </tbody>
                  </table>
                </div>


                <div class="overflow-auto" v-if="showAllDetails">
                  <div class="fw-bolder p-5 fs-2 d-flex justify-content-between">
                    <div>
                      Tous les Détails
                    </div>
                    <div>
                      <div class="btn btn-icon btn-xs btn-primary" v-b-modal="'detailModal'">
                        <i class="fad fa-plus"></i>
                      </div>
                    </div>

                    <b-modal :title="trans('Ajouter un detail')" centered id="detailModal" ref="detailModal">
                      <form>
                        <div class="row">
                          <div class="col-auto m-2 flex-grow-1">
                            <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
                            <b-form-input :id="'label'" :state="!helper.empty(detail.name)"
                              v-model="detail.name"></b-form-input>
                          </div>
                          <div class="col-auto m-2 flex-grow-1">
                            <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
                            <b-form-input :id="'code'" v-model="detail.code"></b-form-input>
                          </div>
                        </div>
                      </form>
                      <template #modal-footer="{ ok, cancel, hide }">
                        <b-button @click="cancel()" size="sm" variant="secondary">
                          <i class="fad fa-times"></i>
                          {{ trans('Cancel') }}
                        </b-button>
                        <b-button @click="addDetail()" size="sm" variant="primary">
                          <i class="fad fa-save"></i>
                          {{ trans('Save') }}
                        </b-button>
                      </template>
                    </b-modal>
                  </div>
                  <table class="table table-striped gs-5 gy-2" v-if="result">
                    <thead></thead>
                    <tbody>
                      <template v-for="detail in result.details">
                        <tr class="bg-hover-secondary" :key="detail.code">
                          <td>
                            {{ detail.name }}
                          </td>
                          <td class="text-end fw-bolder text-primary">
                            <div class="d-flex">
                              <b-form-input :value="detail.value" class="text-end"
                                @change="updateUserData(detail, $event)"></b-form-input>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>

                <div class="d-flex justify-content-center py-5">
                  <label class="btn btn-primary" v-if="!showAllDetails">
                    <input type="checkbox" v-model="showAllDetails" class="hidden">
                    Afficher tous les details
                  </label>
                  <label class="btn btn-primary" v-else>
                    <input type="checkbox" v-model="showAllDetails" class="hidden">
                    Masquer tous les details
                  </label>
                </div>
                <!--                                        <label class="border-top py-5 text-center d-flex align-items-center justify-content-center cursor-pointer btn btn-light-danger rounded-bottom rounded-top-0">-->
                <!--                                            <input class="hidden" type="checkbox" v-model="result.expand">-->
                <!--                                            <i class="fad fa-arrow-up me-3 fs-2 "></i> Masquer les details-->
                <!--                                        </label>-->
                <!--                                    <label class="border-top py-5 text-center d-flex align-items-center justify-content-center cursor-pointer btn btn-light-danger rounded-bottom rounded-top-0"-->
                <!--                                           v-else-if="result">-->
                <!--                                        <input class="hidden" type="checkbox" v-model="result.expand">-->
                <!--                                        <i class="fad fa-arrow-down me-3 fs-2 "></i> Voir les details-->
                <!--                                    </label>-->
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>


  </template>
  </Base>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import Item from "@/entity/Item";
import Loading from "@/components/Loading.vue";
import AddRemoveItemLine from "@/views/Income/Avenant/AvenantManager/AddRemoveItemLine.vue";
import ItemType from "@/entity/ItemType";
import ItemTree from "@/views/Configuration/Branch/Item/ItemTree.vue";
import Avenant from "@/entity/Avenant";
import Duration from "@/entity/Duration";
import ItemManager from "../Components/AvenantItemManager.vue";
import Insurer from "@/entity/Insurer";
import PropertyChoice from "@/entity/PropertyChoice";
import { helper } from "@/services/Helper"
import PremiumDetailModel from "@/entity/PremiumDetailModel";
import PremiumDetail from "@/entity/PremiumDetail";
import { auth } from "@/services/Auth";
import Popup from "@/entity/Popup";
import router from "@/router";

@Component({
  components: { AddRemoveItemLine, Loading, Base, ItemManager },
})
export default class AvenantEdit extends Vue {
  root: Item | null = null;
  avenant: Item | null = null;
  loading = false;
  itemTypes: ItemType[] = [];
  durations: Duration[] = []
  premiumDetailModels: PremiumDetailModel[] = []
  helper = helper
  userData: any = {}
  detail = {
    name: '',
    code: '',
    value: 0
  }

  result: any = null
  showAllDetails = true

  premiumDetails: any[] = []
  mounted() {
    this.loadRoot();
    this.loadDurations()
  }


  loadResult(data:any) {

    this.finalPremium = data.premium ?? 0
    const result = {
      uuid: this.$route.params.uuidAvenant ??  helper.generateId(),
      premium: data.premium ?? this.getPremium(),
      details: data.details,
      // expand: expand,
      insurer: this.avenantInsurer
    }

    // this.results.push(result)
    this.result = result
  }

  uploadRoot = api.uploadRoot

  async save() {
    const pop = new Popup(
      "Chargement",
      "Enregistrement de l'avenant",
      "primary",
      "fad fa-sync",
      true
    );

    const res = await api.post(
      api.core,
      "contract/edit/" + this.$route.params.uuidContrat,
      {
        typeAvenantId: this.$route.params.idTypeAvenant,
        avenantId: this.$route.params.uuidAvenant,
        root: this.root,
        avenant: this.avenant,
        details: this.result.details,
        premium: this.finalPremium,
        premiumDetails: this.premiumDetails,
      }
    );

    pop.hide()
    this.$router.push({ name: 'avenant-show', params: { uuid: res.data.uuid } })
  }


  updateUserData(detail: any, event: any) {
    // console.log(detail)
    // console.log(event)
    // console.log(auth.getCurrentUser())
    this.userData[detail.code] = {
      label: detail.name,
      value: parseFloat(event + ''),
      source: auth.getCurrentUser().uuid
    }
    // console.log(this.userData)
    // console.log(this.result)
  }


  avenantInsurer: Insurer = new Insurer()
  getDetail(model: PremiumDetailModel) {
    if (this.result && this.premiumDetails) {
      return this.premiumDetails.find((pd: PremiumDetail) => {
        return pd.model.id === model.id
      })
    }
  }


  addDetail() {

    if (this.result) {
      this.result.details.push(this.detail)
      this.detail = { name: '', value: 0, code: '' };
      (this.$refs.detailModal as any).hide()
    }
  }



  saveDetail(detail: PremiumDetailModel) {

    // console.log(detail,'detail');
    let index = this.premiumDetails.findIndex((pd: PremiumDetail) => {
      return pd.model.id === detail.id
    })

    // console.log(detail.label)
    // console.log(detail.value)
    // console.log(premiumDetail.premium)
    // console.log(index)
    // return
    if (index === -1) {
      let pd = new PremiumDetail()
      pd.model = detail
      pd.premium = detail.value
      this.premiumDetails.push(pd)

    } else {
      this.premiumDetails[index].premium = detail.value
    }
    if (detail.method) {
      this.userData[detail.method.code] = {
        label: detail.label,
        value: parseFloat(detail.value + ''),
        source: auth.getCurrentUser().uuid
      }
    } else {
      this.userData[helper.slugify(detail.label)] = {
        label: detail.label,
        value: parseFloat(detail.value + ''),
        source: auth.getCurrentUser().uuid
      }
    }


    this.finalPremium = 0
    this.premiumDetails.map((item: PremiumDetail) => {

      this.finalPremium = this.finalPremium + parseFloat(item.premium + '')

    })
    // this.compare()
  }

  editDetail(detail: PremiumDetailModel) {
    const premiumDetail = this.getDetail(detail)
    if (premiumDetail) {
      detail.value = premiumDetail.premium
    }
  }

  async loadRoot() {

    this.loading = true;
    const res = await api.get(
      api.core,
      "contract/get/new/avenant/data/" + this.$route.params.uuidContrat + "/" + (this.$route.params.uuidAvenant ?? null)
    );

    // const resData = await api.get(
    //   api.core,
    //   "contract/get/root/item/" + this.$route.params.uuid
    // );
    this.loading = false;

    if (res && res.data && res.data.items) {
      res.data.items.forEach((item: Item) => {
        if (item.model.code == "racine") {

          this.root = new Item(item);
          this.itemTypes = [];
          res.data.itemTypes.forEach((i: any) => {
            const occ = new ItemType(i);
            if (!this.itemTypes.includes(occ)) {
              this.itemTypes.push(occ);
            }
          });
        }

        if (item.model.code == "avenant") {
          this.avenant = new Item(item);
        }

        this.avenantInsurer = res.data.insurer as Insurer

        this.premiumDetailModels = []

        // console.log(res.data);
        res.data.premiumDetailModels.forEach((pdm: PremiumDetailModel) => {
          const occ = new PremiumDetailModel(pdm)
          this.premiumDetailModels.push(occ)
        })
      });

      

      this.loadResult(res.data)

      // console.log(this.root);

      // console.log(res,'res');
      // this.root = new Item(res.data.root)
      // this.itemTypes = []
      // res.data.itemTypes.forEach((i: any) => {
      //   const occ = new ItemType(i)
      //   if (!this.itemTypes.includes(occ)) {
      //     this.itemTypes.push(occ)
      //   }
      // })
    }
  }




  newAvenant = new Avenant();

  // async loadNewAvenant() {
  //   this.loading = true
  //   const res = await api.get(api.core, 'contract/get/avenants/' + this.$route.params.uuid)
  //   this.loading = false

  //   if (res && res.data) {

  //     res.data.avenants.forEach((item: Avenant) => {
  //       if (item.status == 'EN_COURS') {
  //         this.newAvenant = item
  //       }
  //     });
  //     // this.newAvenant

  //     console.log(this.newAvenant, 'okokok');

  //   }

  // }

  // durees: Duration[] = []
  // async loadDuree() {

  //   this.loading = true
  //   const res = await api.get(api.master, 'api/get/durations')

  //   if (res.data.durations) {
  //     res.data.durations.forEach((duration: Duration) => {
  //       this.durees.push(duration)
  //     });
  //   }

  //   this.loading = false

  // }


  async loadDurations() {
    const res = await api.get(api.master, 'api/get/durations')
    if (res && res.data && res.data.durations) {
      this.durations = []
      res.data.durations.forEach((dur: Duration) => {
        this.durations.push(new Duration(dur))
      })
    }
  }


  getDuree() {
    if (this.avenant instanceof Item) {
      const prop = this.avenant.getPropertyByLink('duree')
      if (!prop) {
        console.log('Propriété duree introuvable')
        return
      }
      const choice = prop.model.choices.find((c: PropertyChoice) => {
        return c.code === prop.value
      })
      return choice ? choice.label : ''
    }
  }

  getStart() {
    if (this.avenant instanceof Item) {
      // console.log('start ' + this.avenant.getPropertyByLink('dateEffet').value)
      return this.avenant.getPropertyByLink('dateEffet').value
    } else {
      // console.log('this.avenant is not item, cannot determinate start date')
    }
  }

  editPremium = false
  finalPremium = 0

  getPremium() {
    if (this.avenant instanceof Item) {
      // console.log('start ' + this.avenant.getPropertyByLink('dateEffet').value)
      // console.log(this.avenant,'ijtijtij')
      // console.log(this.avenant.getPropertyByLink('primeTTC').value);
      this.finalPremium = this.avenant.getPropertyByLink('primeTTC').value
      // return this.avenant.getPropertyByLink('primeTTC').value
    } else {
      // console.log('this.avenant is not item, cannot determinate start date')
    }
  }

  get start() {
    return this.getStart()
  }

  get end() {
    return this.getEnd()
  }

  getEnd() {
    if (this.avenant instanceof Item) {
      var debut = this.avenant.getPropertyByLink('dateEffet').value
      var duree = this.avenant.getPropertyByCode('duree').value
      // console.log(duree);
      if (!helper.empty(debut)) {
        debut = new Date(debut)
        const duration: Duration = this.durations.find((duration: Duration) => {
          return duration.code === duree
        }) as any
        // console.log('duration = ' + duration?.label)
        // console.log(duration)
        var endDate = new Date(debut);
        if (duration.year > 0) {
          endDate.setFullYear(endDate.getFullYear() + duration.year)
        }
        if (duration.month > 0) {
          endDate.setMonth(endDate.getMonth() + duration.month)
        }
        if (duration.day > 0) {
          endDate.setDate(endDate.getDate() + duration.day)
        }
        endDate.setSeconds(endDate.getSeconds() - 1)
        // console.log('end at ' + endDate)
        return endDate
      }
    } else {
      // console.log('this.avenant is not item, cannot calculate end date')
    }

  }

  dureeEnJour() {
    if (this.avenant instanceof Item) {
      var debut = this.avenant.getPropertyByLink('dateEffet').value
      debut = new Date(debut)
      var end = this.getEnd()
      if (end) {
        var diff = end.getTime() - debut.getTime()
        var delta = Math.ceil(diff / (1000 * 3600 * 24))
        return delta
      }
      return null
    }
  }
}
</script>

<style scoped></style>
