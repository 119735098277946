import BranchList from "@/views/Configuration/Branch/BranchList.vue";
import SurveyList from "@/views/Configuration/Branch/SurveyList.vue";
import SurveyForm from "@/views/Configuration/Branch/SurveyForm.vue";
import QuestionForm from "@/views/Configuration/Branch/SurveyComponents/QuestionForm.vue";
import ItemTypeList from "@/views/Configuration/Branch/Item/ItemTypeList.vue";
import PlatformList from "@/views/Configuration/Platform/PlatformList.vue";
import InsurerList from "@/views/Configuration/Insurer/InsurerList.vue";
import PackCategoryList from "@/views/Configuration/PackCategoryList.vue";
import ContractParameters from "@/views/Configuration/Branch/ContractParameters.vue";
import MapperIndex from "@/views/Configuration/Branch/Mapper/MapperIndex.vue";
import DataTypeManager from "@/views/Configuration/DataTypeManager.vue";
import PackForm from "@/views/Configuration/Insurer/PackForm.vue";
import PremiumDetailList from "@/views/Configuration/Branch/PremiumDetailList.vue";
import MethodList from "@/views/Engine/MethodList.vue";
import EngineList from "@/views/Engine/EngineList.vue";
import EngineMethodEditor from "@/views/Engine/EngineMethodEditor.vue";
import LanguageList from "@/views/Translator/LanguageList.vue";
import DurationManager from "@/views/Configuration/DurationManager.vue";
import PasswordUpdate from "@/views/User/PasswordUpdate.vue";
import InsurerForm from "@/views/Configuration/Insurer/InsurerForm.vue";
import Parameters from "@/views/Configuration/Parameters.vue";
import UiPlatformList from "@/views/Translator/UiPlatformList.vue";
import TagManager from "@/views/Configuration/TagManager.vue";

import ReasonManager from "@/views/Configuration/ReasonManager.vue";
import AvenantTypeList from "@/views/Configuration/AvenantType/AvenantTypeList.vue";
import AvenantTypeForm from "@/views/Configuration/AvenantType/AvenantTypeForm.vue";

const prefix = '/configuration'
const configurationRoutes = [
    {
        path: '/',
        name: 'configuration-index',
        component: BranchList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/guarantee/:branchSlug',
        name: 'guarantee-list',
        component: () => import("@/views/Configuration/Branch/GuaranteeList.vue"),
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/pack/category/edit/:branchSlug/:packCategorySlug',
        name: 'pack-category-form',
        // component: GuaranteeList,
        component: () => import("@/views/Configuration/Insurer/PackCategoryForm.vue"),
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/parameters',
        name: 'parameters',
        component: Parameters,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/data/type',
        name: 'data-type-manager',
        component: DataTypeManager,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },

    {
        path: '/avenant/type',
        name: 'avenant-type-manager',
        component: AvenantTypeList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/avenant/type/form/:id?',
        name: 'avenant-type-form',
        component: AvenantTypeForm,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },


    {
        path: '/tag',
        name: 'tag-manager',
        component: TagManager,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/reason',
        name: 'reason-manager',
        component: ReasonManager,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/duration',
        name: 'duration-manager',
        component: DurationManager,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/branch/list',
        name: 'branches-list',
        component: BranchList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/branch/contract/parameters/:slug',
        name: 'branch-parameters',
        component: ContractParameters,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/update/password/:email/:code',
        name: 'password-update',
        component: PasswordUpdate,
    },
    {
        path: '/pack-category/list',
        name: 'pack-category-list',
        component: PackCategoryList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },

    {
        path: '/platform/list',
        name: 'platform-list',
        component: PlatformList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },

    {
        path: '/insurer/list',
        name: 'insurer-list',
        component: InsurerList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },

    {
        path: '/insurer/form/:slug',
        name: 'insurer-form',
        component: InsurerForm,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },

    {
        path: '/pack/:insurerSlug/:branchSlug/:packId?',
        name: 'pack-form',
        component: PackForm,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    // {
    //     path: '/branch/form/:slug?',
    //     name: 'branch-form',
    //     component: BranchForm,
    //     meta: {requiredRoles: ['ROLE_MANAGER']},
    // },
    {
        path: '/branch/:slug/survey/list',
        name: 'form-list',
        component: SurveyList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/survey/list/:slug?',
        name: 'orphan-form-list',
        component: () => import("@/views/Configuration/OrphanFormList.vue"),
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/branch/:branchSlug?/survey/form/:surveySlug?',
        name: 'survey-form',
        component: SurveyForm,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/map/branch/:branchSlug/survey/:surveySlug',
        name: 'mapper-index',
        component: MapperIndex,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/branch/question/form/:sectionUuid?',
        name: 'question-form',
        component: QuestionForm,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/branch/:slug/item/list',
        name: 'item-list',
        component: ItemTypeList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/premium/details/:branchSlug',
        name: 'premium-detail-list',
        component: PremiumDetailList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/engine/:branchSlug',
        name: 'engine-index',
        component: EngineList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/language/list',
        name: 'language-list',
        component: LanguageList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/language/platform/translation/:id',
        name: 'platform-translation',
        component: UiPlatformList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    // {
    //     path: '/user/list',
    //     name: 'user-list',
    //     component: UserList,
    //     meta: { requiredRoles: ['ROLE_ADMIN'] },
    // },
    {
        path: '/engine/method/list/version/:engineVersion/:branchSlug?',
        name: 'engine-method-list',
        component: MethodList,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
    {
        path: '/engine/method/editor/version/:engineVersion/branch/:branchSlug/id/:methodId/slug/:methodSlug?',
        name: 'engine-method-editor',
        component: EngineMethodEditor,
        meta: {requiredRoles: ['ROLE_MANAGER']},
    },
]
configurationRoutes.forEach((route) => {
    route.path = prefix + route.path
})

export default configurationRoutes
