
const prefix = '/comparateur'
const comparatorRoutes = [
    {
        path: '/',
        name: 'comparateur-index',
        component: () => import("@/views/Comparator/ComparatorHome.vue"),
    },
    {
        path: '/calculator',
        name: 'comparateur-calculator',
        component: () => import("@/views/Comparator/Calculator.vue"),
    },
    {
        path: '/questionnaire/:surveySlug/:rgUuid',
        name: 'response-group-manager',
        component: () => import("@/views/Comparator/Components/ResponseGroupManager.vue"),
    },
    {
        path: '/souscription/:uuid',
        name: 'order-view',
        component: () => import("@/views/Comparator/Components/OrderView.vue"),
    },
    {
        path: '/order/verification/status/:uuid',
        name: 'order-check-status',
        component: () => import("@/views/Comparator/Components/OrderCheckStatus.vue"),
    },

    {
        path: '/avenant/verification/status/:contractUuid/:avenantUuid',
        name: 'avenant-check-status',
        component: () => import("@/views/Comparator/Components/AvenantCheckStatus.vue"),
    },

    {
        path: '/update/contract/offers/:contractUuid',
        name: 'update-contract-offers',
        component: () => import("@/views/Comparator/ContractOfferUpdater.vue"),
    },
    {
        path: '/renouveller/contrat/:contractUuid',
        name: 'renew-contract',
        component: () => import("@/views/Comparator/ContractRenewal.vue"),
    },
    {
        path: '/avenant/payment/:uuid/:method?',
        name: 'avenant-payment',
        component: () => import("@/views/Comparator/AvenantPayment.vue"),
    },
    
    {
        path: '/order/payment/:uuid/:method?',
        name: 'order-payment',
        component: () => import("@/views/Comparator/OrderPaymentLink.vue"),
    },

    {
        path: '/files/:uuid',
        name: 'contract-files',
        component: () => import("@/views/Comparator/Components/ContractFiles.vue"),
    },
    {
        path: '/avenant/files/:uuid',
        name: 'avenant-files',
        component: () => import("@/views/Comparator/Components/AvenantFiles.vue"),
    },
    {
        path: '/wait/for/delivery',
        name: 'wait-delivery',
        component: () => import("@/views/Comparator/Components/WaitingForDelivery.vue"),
    },

    {
        path: '/comment/:rgUuid',
        name: 'comments',
        component: () => import("@/views/Comparator/CommentView.vue"),
    },


]
comparatorRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default comparatorRoutes;
