<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'crm-index' }" class="text-muted text-hover-primary">
                  <i class="fad fa-building  "></i>
                  {{ trans('Entreprise') }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>


        <div class="d-flex align-items-center py-1">
          <button @click="EditModal(null)" class="btn btn-primary btn-sm">
            <i class="fad fa-building"></i>
            {{ trans('Ajouter une entreprise') }}
          </button>


        </div>


        <b-modal id="modal-center" @ok="handleOk" centered title="Ajouter une reunion">
          <div class="my-4">
            <form ref="form" @submit.stop.prevent="handleSubmit">



              <b-form-group id="input-group-2" label="Nom de l'entreprise" label-for="input-2">
                <input type="text" v-model="company.name" class="mb-2 form-control">
              </b-form-group>
              <b-form-group id="input-group-2" label="Secteur" label-for="input-2">

                <input type="text" v-model="company.sector" class="mb-2 form-control">
              </b-form-group>


              <b-form-group id="input-group-2" label="Adresse" label-for="input-2">

                <input type="text" v-model="company.localisation" class="mb-2 form-control">
              </b-form-group>

              <b-form-group id="input-group-2" label="Email de l'entreprise" label-for="input-2">

                <input type="email" v-model="company.email" class="mb-2 form-control">
              </b-form-group>

              <b-form-group id="input-group-2" label="Numero de telephone" label-for="input-2">

                <input type="tel" v-model="company.number" class="mb-2 form-control">
              </b-form-group>


            </form>

          </div>
        </b-modal>
      </div>
    </div>
  </template>

  <template v-slot:content>

    <div class="card">


      <div class="w-100">
        <Pagination :count="total" :itemLength="companies.length" :matchCount="total" v-model="params.page"
          showEmpty="true" class="p-10" :perPage="params.limit" :key="'pagination_' + params.page"
          @input="loadCompanies()">
        </Pagination>
      </div>
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr class="fw-bolder text-muted">

                <th class="min-w-150px">{{ trans('Entreprise') }}</th>
                <th class="min-w-140px">{{ trans('Employés') }}</th>
                <!--              <th v-if="!compact">-->
                <!--                Prochaine activité-->
                <!--              </th>-->
                <th class="min-w-120px">{{ trans("Contacts") }}</th>

              </tr>
            </thead>
            <tbody>
              <!-- <tr v-if="rgs.length === 0">
                <td colspan="9">
                  <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                                border-bottom-dashed border-gray-300">
                    <i class="fad fa-empty-set fs-4x"></i>
                    <span class="mt-5 fs-2x text-gray-400">
                      {{ trans('Aucun resultat') }}
                    </span>
                  </div>
                </td>
              </tr>

              -->
              <tr v-for="item, index in companies" :key="index">
                <td>
                  <span class=" fw-bolder text-gray-400 uppercase">
                    {{ item.name }}
                  </span>
                  <p>
                    {{ item.sector }}
                  </p>

                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="d-flex justify-content-start flex-column">
                      <div class="text-dark text-uppercase fw-bolder text-hover-primary fs-6" target="_blank">
                        <!--                      {{ rg.survey.branch }}-->
                        {{ item.uniqProspects.length }}

                      </div>

                      <!--a :class="[rg.location.vpn ? 'text-danger' : 'text-primary']"
                        v-if="rg.location">
                        <i class="fad fa-map-marker-alt text-primary me-1"></i>
                        {{ rg.location.city }}, {{ rg.location.country }}
                        <template v-if="!compact">▪️ <span>{{ rg.location.ip }}</span></template>
</a-->
                    </div>
                  </div>
                </td>

                <td>

                  <template v-if="!empty(item.number)">
                    <div class="d-flex flex-wrap">
                      <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2">
                        {{ helper.phoneFormat(item.number) }}
                        <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                          <i class="fad fa-phone"></i>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-if="!empty(item.email)">
                    <span class="fw-bolder text-gray-400 mt-1">
                      {{ item?.email }}
                    </span>
                  </template>

                </td>

                <td>
                  <div class="d-flex ">

                    <router-link class="" :to="{
                  name: 'detail-company',
                  params: { id: item?.id },
                }">
                      <button class="btn btn-sm btn-icon btn-primary">
                        <i class="fad fa-eye"></i>
                      </button>
                    </router-link>

                    <router-link class="mx-2" :to="{
                  name: 'handshake-form',
                  params: { clientId: 'null', uuid: 'null', entrepriseId: item?.id },
                }">
                      <button class="  btn btn-sm btn-icon btn-success">
                        <i class="fad fa-phone"></i>
                      </button>

                    </router-link>


                    <button @click="EditModal(item)" class="btn btn-sm btn-icon btn-secondary ">
                      <i class="fad fa-edit"></i>
                    </button>
                  </div>

                </td>


                <!--              <td v-if="!compact">-->
                <!--              </td>-->


              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>



  </template>

  </Base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import Base from "@/layouts/Base.vue";
import Popup from '@/entity/Popup';
import { api } from '@/services/Api';
import Company from '@/entity/Company';
import Pagination from "@/components/Pagination.vue";
@Component({
  components: { Base, Pagination },
})
export default class CompanyView extends Vue {



  company = new Company()

  companies: Company[] = []
  total = null

  params: any = {
    limit: 20,
    page: 1,
  };



  async loadCompanies() {
    const pop = new Popup(
      "Chargement",
      "Liste des entreprises",
      "primary",
      "fad fa-sync",
      true
    );
    const res = await api.get(
      api.form,
      `company/list?limit=${this.params.limit}&page=${this.params.page}`
    );


    if (res && res.data) {
      this.total = res.data.total;
      this.companies = [];
      res.data.companies.forEach((c: any) => {
        const occ = new Company(c);
        this.companies.push(occ);
      });

      pop.hide();
    }
  }


  mounted() {
    this.loadCompanies()
  }


  EditModal(item: Company | null) {
    this.$bvModal.show("modal-center");

    if (item) {
      console.log("object");
      this.company = item;
    } else {
      this.company = new Company();

    }
  }



  async handleOk(bvModalEvent: { preventDefault: () => void; }) {


    const pop = new Popup(
      "Chargement",
      "Enregistrement d'un entreprise",
      "primary",
      "fad fa-sync",
      true
    );

    try {
      const res = await api.post(api.form, "company/edit",
        this.company
      );

      if (res.status == 200) {
        pop.hide();
        this.loadCompanies();
      }
    } catch (error) {
      pop.hide();
    }

    bvModalEvent.preventDefault()
    // Trigger submit handler
  }
  async handleSubmit() {



    // // Exit when the form isn't valid

    // // Push the name to submitted names

    // // Hide the modal manually
    // this.$nextTick(() => {
    //   this.$bvModal.hide('modal-prevent-closing')
    // })
  }
}
</script>
<style lang="">

</style>