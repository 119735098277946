<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <Base>
    <template v-slot:content>

      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-9 pb-0">
          <Loading v-if="isLoading"></Loading>
          <div class="d-flex flex-wrap flex-sm-nowrap mb-3" v-else-if="partner">
            <div class="me-7 mb-4">
              <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img :src="uploadRoot + partner.photo" alt="image" class="object-cover" v-if="partner && partner.photo">
                <div
                    class="w-100px h-100px h-lg-175px w-lg-160px d-flex align-items-center justify-content-center shadow-xs rounded"
                    v-else>
                  <i class="fad fa-user fs-5x"></i>
                  <!--                                    <img  alt="image" class="object-cover" style="opacity: 0">-->
                </div>
                <!--                <div :class="{-->
                <!--                                'bg-success': contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,-->
                <!--                                'bg-warning': contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,-->
                <!--                                'bg-danger': contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,-->
                <!--                                'bg-primary': contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED-->
                <!--                            }"-->
                <!--                     class="position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px">-->
                <!--                </div>-->
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2">
                    <div class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {{ partner.name }}
                    </div>
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                    {{ partner.code }}
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">

                  </div>

                </div>
                <div>
                  <div class="btn btn-primary" v-b-modal="'editPartner'">
                    Modifier
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap flex-stack">
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <div class="d-flex flex-wrap">
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <i class="fad fa-coins"></i>
                      </span>
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-prefix="$"
                             data-kt-countup-value="4500">
                          ---
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Solde</div>
                    </div>


                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <i class="fad fa-coins"></i>
                      </span>
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-prefix="$"
                             data-kt-countup-value="4500">
                          ---
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Commission</div>
                    </div>

                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <i class="fad fa-coins"></i>
                      </span>
                        <div class="fs-2 fw-bolder counted">
                          ---
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Valeur Client</div>
                    </div>
                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-body">
          <b-tabs active-nav-item-class="" active-tab-class="" nav-wrapper-class="nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap
                            flex-grow-1">
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadContracts">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-layer-group fs-2 me-2"></i>
                  {{ trans('Contrats') }}
                </div>
              </template>
              <div>
                <table class="table table-striped gy-3 gs-3">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Type</th>
                    <th>Période</th>
                    <th>Montant/Reste</th>
                    <th>Apporteur</th>
                    <th>Agent</th>
                    <th>Statut</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="loadingContracts">
                    <td colspan="7">
                      <Loading/>
                    </td>
                  </tr>
                  <tr v-for="contract in contracts" :key="'c_' + contract.uuid">
                    <td>
                      <div>

                        <div class="text-gray-400 fw-bolder d-flex align-items-center">
                          <i :class="'fad fs-2 ' + contract.branch.icon" class="me-2 text-gray-400"></i>
                          {{ contract.branch.label }} ▪ {{ contract.branch.category.label }}
                        </div>
                        <router-link :to="{ name: 'contract-show', params: { contractUuid: contract.uuid } }"
                                     class="d-flex align-items-center fw-bolder">
                          {{ contract.insurer.label }} ▪ {{ contract.number }}
                        </router-link>

                        <!--                      <div class="me-2">-->
                        <!--                        Créer le {{ readableDate(contract.createdAt, 'dMY H:i') }}-->
                        <!--                      </div>-->
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="fw-bolder align-items-center">
                        <div :title="readableDate(contract.startAt, 'dmY')" v-b-tooltip.hover v-if="contract.startAt">
                          {{ readableDate(contract.startAt, 'dMY') }}
                        </div>
                        <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover v-if="contract.expireAt">
                          {{ readableDate(contract.expireAt, 'dMY') }}
                        </div>
                      </div>
                    </td>
                    <td class="text-end">
                    <span class="fw-bolder">
                      {{ currency(contract.primeTTC) }}
                    </span>
                      <br>
                      <span class="text-danger fw-bolder">
                      {{ currency(contract.balance, true) }}
                    </span>
                    </td>
                    <td>
                      <template v-if="contract.partner">
                        <div class="fw-bolder">
                          {{ contract.partner.name }}
                        </div>
                        <div class="text-gray-400 fw-bolder fs-8">
                          {{ contract.partner.code }}
                        </div>
                      </template>
                    </td>
                    <td>
                      <user-view v-model="contract.manager"></user-view>
                    </td>
                    <td>
                      <div>
                      <span :class="{
          'badge-primary': contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED,
          'badge-warning': contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,
          'badge-success': contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,
          'badge-danger': contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,
        }" class="badge">
                        {{ contract.status }}
                      </span>
                      </div>
                    </td>
                    <td>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-building fs-2 me-2"></i>
                  {{ trans('Agences') }}
                </div>
              </template>
              <div>
                <div class="d-flex justify-content-end">
                  <div class="d-flex justify-content-end">
                    <input type="text" class="form-control" placeholder="Rechercher" v-model="searchAgency">
                  </div>
                </div>
                <PartnerTree v-if="partner" :partner="partner" v-on:createSub="createSub($event)" :search="searchAgency"
                             v-on:edit="editAgency($event)" v-on:delete="deletePartner"></PartnerTree>
              </div>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadTenants"
                   v-if="hasRole('ROLE_MANAGER')">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-database fs-2 me-2"></i>
                  {{ trans('Tenants') }}
                </div>
              </template>
              <div class="">
                <b-tabs active-nav-item-class="" active-tab-class=""
                        nav-wrapper-class="bg-white px-10 mb-10 rounded-bottom nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap flex-grow-1">
                  <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" v-for="tenant in tenants"
                         :key="tenant.id">
                    <template #title>
                      <div class="d-flex align-items-center ">
                        {{ tenant.label }}
                      </div>
                    </template>
                    <template #default>
                      <div class="card">
                        <div class="card-body">
                          <template v-if="partnerHasRole(partner, 'ROLE_' + tenant.code.toUpperCase())">
                            <div class="d-flex justify-content-center m-20">
                              <div class="btn btn-danger" @click="toggleTenant(tenant)">
                                Retirer l'accès au tenant {{ tenant.label }}
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="d-flex justify-content-center p-20 bg-light-primary rounded m-20">
                              <div class="btn btn-primary" @click="toggleTenant(tenant)">
                                Autorisé l'accès au tenant {{ tenant.label }}
                              </div>
                            </div>
                          </template>

                        </div>
                      </div>
                    </template>
                  </b-tab>
                </b-tabs>

              </div>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" v-if="hasRole('ROLE_MANAGER')">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-ballot-check fs-2 me-2"></i>
                  {{ trans('Formulaires') }}
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadUsers">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-building fs-2 me-2"></i>
                  {{ trans('Liens') }}
                </div>
              </template>
              <div class="accordion" role="tablist">

                <div v-if="partner">

                  <b-card no-body class="mb-1">

                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button v-b-toggle.accordion-1 variant="primary" class="uppercase w-100">PLATEFORMES</b-button>

                    </b-card-header>

                    <b-collapse id="accordion-1" accordion="my-accordion" class="mt-2" role="tabpanel">

                      <div class="bg-light-primary d-flex flex-wrap">
                        <div class="position-relative m-20 p-10 rounded" style="background: #e0338a"
                             :set="url = getUrlPlatform({ name: 'comparateur-index', query: { 'source': partner.code } })">
                          <!--                  <qr-code text="Hello World!" class="p-10 m-10 bg-white rounded position-relative"></qr-code>-->
                          <!--                  :logoSrc="getBranchLogoBySlug(survey.branch)" :logoMargin="10" :logoCornerRadius="0"-->

                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">

                          </a>
                          <div class=" fw-bolder text-inverse-primary text-uppercase my-5 w-100 text-center">
                            Backoffice
                          </div>
                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">
                            <vue-qr colorDark="black" colorLight="white" :autoColor="true"
                                    class=" rounded position-relative" :text="url" :whiteMargin="true"
                                    :size="300"></vue-qr>
                          </a>
                          <div>

                            <!--                    <a target="_blank" :href="url" class="my-5">-->
                            <!--                      {{ url }}-->
                            <!--                    </a>-->
                          </div>
                        </div>

                        <div class="position-relative m-20 p-10 rounded" style="background: #e0338a"
                             :set="url = getUrlComparator(partner.code)">
                          <!--                  <qr-code text="Hello World!" class="p-10 m-10 bg-white rounded position-relative"></qr-code>-->
                          <!--                  :logoSrc="getBranchLogoBySlug(survey.branch)" :logoMargin="10" :logoCornerRadius="0"-->

                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">

                          </a>
                          <div class=" fw-bolder text-inverse-primary text-uppercase my-5 w-100 text-center">
                            Comparateur
                          </div>
                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">
                            <vue-qr colorDark="black" colorLight="white" :autoColor="true"
                                    class=" rounded position-relative" :text="url" :whiteMargin="true"
                                    :size="300"></vue-qr>
                          </a>
                          <div>

                            <!--                    <a target="_blank" :href="url" class="my-5">-->
                            <!--                      {{ url }}-->
                            <!--                    </a>-->
                          </div>
                        </div>


                      </div>
                    </b-collapse>

                  </b-card>


                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button v-b-toggle.accordion-2 variant="primary" @click="loadSurveys" class="uppercase w-100">
                        FORMULAIRES
                      </b-button>
                    </b-card-header>

                    <b-collapse id="accordion-2" accordion="my-accordion" class="mt-2" role="tabpanel">

                      <div v-if="surveys.length > 0" class="bg-light-primary d-flex flex-wrap">
                        <div v-for="survey in surveys" class="position-relative m-10 p-5 rounded"
                             style="background: #e0338a"
                             :set="url = getUrl({ name: 'response-group-manager', params: { surveySlug: survey.slug, rgUuid: 'new' }, query: { 'source': partner.code } })">
                          <!--                  <qr-code text="Hello World!" class="p-10 m-10 bg-white rounded position-relative"></qr-code>-->
                          <!--                  :logoSrc="getBranchLogoBySlug(survey.branch)" :logoMargin="10" :logoCornerRadius="0"-->
                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">
                            <img :src="getBranchLogoBySlug(survey.branch)" alt="" class=" w-80px"
                                 style="filter:invert(100%)">
                          </a>
                          <div class=" fw-bolder text-inverse-primary text-uppercase my-5 w-100 text-center">
                            {{ getBranchLabelBySlug(survey.branch) }}
                          </div>
                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">
                            <vue-qr colorDark="black" colorLight="white" :autoColor="true"
                                    class=" rounded position-relative" :text="url" :whiteMargin="true"
                                    :qid="survey.uuid"
                                    :size="300"></vue-qr>
                          </a>
                          <div>
                            <div class=" fw-bolder text-inverse-primary text-uppercase w-100 text-center mt-5">
                              ️ {{ survey.label }}
                            </div>
                            <!--                    <a target="_blank" :href="url" class="my-5">-->
                            <!--                      {{ url }}-->
                            <!--                    </a>-->
                          </div>
                        </div>
                      </div>

                      <Loading v-else/>
                    </b-collapse>
                  </b-card>


                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button v-b-toggle.accordion-3 variant="primary" class="uppercase w-100">WHATSAPP</b-button>
                    </b-card-header>

                    <b-collapse id="accordion-3" accordion="my-accordion" class="mt-2" role="tabpanel">

                      <div class="bg-light-primary d-flex flex-wrap">
                        <div class="position-relative m-10 p-5 rounded" style="background: #e0338a"
                             :set="url = getWhaUrl(null)">
                          <!--                  <qr-code text="Hello World!" class="p-10 m-10 bg-white rounded position-relative"></qr-code>-->
                          <!--                  :logoSrc="getBranchLogoBySlug(survey.branch)" :logoMargin="10" :logoCornerRadius="0"-->
                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">

                          </a>
                          <b-form-input id="input-1" v-model="message" type="text" class="my-2"
                                        placeholder="Entrez un message"/>

                          <a target="_blank" :href="url" class="text-center d-flex justify-content-center">
                            <vue-qr colorDark="black" colorLight="white" :autoColor="true"
                                    class=" rounded position-relative" :text="url" :whiteMargin="true"
                                    :size="300"></vue-qr>
                          </a>
                          <div>

                            <!--                    <a target="_blank" :href="url" class="my-5">-->
                            <!--                      {{ url }}-->
                            <!--                    </a>-->
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </b-card>


                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button v-b-toggle.accordion-4 variant="primary" class="uppercase w-100">WHATSAPP PAR AGENTS
                      </b-button>
                    </b-card-header>

                    <b-collapse id="accordion-4" accordion="my-accordion" class="mt-2" role="tabpanel">

                      <div class="d-flex justify-content-end" @click="printUserQrCodes">
                        <div class="btn btn-warning">
                          <i class="fad fa-print"></i>
                          Imprimer
                        </div>
                      </div>
                      <div class="my-10">
                        <b-form-input id="input-1" v-model="message" type="text" class="my-2"
                                      placeholder="Entrez un message"/>
                      </div>
                      <div style="display: flex;flex-wrap:wrap;justify-content: center;align-items: flex-start"
                           class="printable"
                           id="user-qr-codes-container">
                        <template v-for="partner in users">
                          <div class="qr-code"
                               style="position: relative;padding:0;border-radius:10px;background:white;border:1px whitesmoke solid;break-inside: avoid;margin:10px"
                               :set="url = getWhaUrl(partner)">
                            <img src="../../../assets/illustrations/rectangle.svg" alt="" style="width:100%">
                            <div class='content-qr'
                                 style="display:flex;position: relative;align-items: center;justify-content: center;padding:20px 20px;border-bottom:1px whitesmoke solid">
                              <a target="_blank" :href="url" class="d-flex justify-content-center position-relative">
                                <vue-qr colorDark="black" colorLight="white" :autoColor="true"
                                        class=" rounded position-relative" :text="url" :whiteMargin="true"
                                        :size="400" style="width:400px;height:400px"></vue-qr>
                                <div
                                    style="position: absolute;left:-5px;top:-5px;width:80px;height:80px;border-top:10px black solid;border-left:10px black solid;border-radius:10px"></div>
                                <div
                                    style="position: absolute;right:-5px;top:-5px;width:80px;height:80px;border-top:10px black solid;border-right:10px black solid;border-radius:10px"></div>
                                <div
                                    style="position: absolute;right:-5px;bottom:-5px;width:80px;height:80px;border-bottom:10px black solid;border-right:10px black solid;border-radius:10px"></div>
                                <div
                                    style="position: absolute;left:-5px;bottom:-5px;width:80px;height:80px;border-bottom:10px black solid;border-left:10px black solid;border-radius:10px"></div>
                              </a>

                            </div>

                            <div style="color:#128997"
                                 class=" uppercase d-flex justify-content-center flex-column p-5 W-100 text-center">

                              <div class="fw-bolder fs-2">
                                {{ partner.name }} | {{ shortener.converIdToCHar(partner.id).toUpperCase() }}
                              </div>
                              <div class="fs-2">
                                COMMERCIAL
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </b-collapse>
                  </b-card>


                </div>


              </div>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadUsers">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-users fs-2 me-2"></i>
                  {{ trans('Utilisateurs') }}
                </div>
              </template>
              <div>
                <div class="d-flex justify-content-end">
                  <div class="btn btn-primary" @click="createUser">
                    <i class="fad fa-plus"></i>
                    Créer
                  </div>
                </div>
                <Loading v-if="loadingUsers"/>
                <table class="table table-striped gy-3 gs-3">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Utilisateur</th>
                    <th>Code</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Agence</th>
                    <th>Créer le</th>
                    <th>Dernière authentification</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="partner in users">
                    <tr style="height: 1px" :key="'p_' + partner.uuid">
                      <td>
                        {{ partner.name }}
                      </td>
                      <td>
                        {{ partner.code }}
                      </td>
                      <td>
                        {{ partner.email }}
                      </td>
                      <td>
                        {{ partner.contact }}
                      </td>
                      <td>
                        {{ getAgencyNameByCode(partner.code) }}
                      </td>
                      <td>
                        {{ readableDate(partner.createdAt, 'dmY H:i') }}
                      </td>
                      <td>
                        {{ readableDate(partner.lastToken, 'dmY H:i') }}
                      </td>

                      <td class="text-end">
                        <div class="d-flex justify-content-end">
                          <div class="btn btn-xs btn-icon btn-primary m-1" @click="editUser(partner)">
                            <i class="fad fa-edit"></i>
                          </div>
                          <div @click="deleteUser(partner.code)" class="btn btn-xs btn-icon btn-danger m-1">
                            <i class="fad fa-ban"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="initCommission"
                   v-if="hasRole('ROLE_MANAGER')">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-file-contract fs-2 me-2"></i>
                  Commissions
                </div>
              </template>
              <div>
                <b-tabs>
                  <b-tab v-for="category in branchCategories" :key="'cat_' + category.uuid">
                    <template #title>
                      <div class="d-flex align-items-center">
                        {{ category.label }}
                      </div>
                    </template>


                    <div>
                      <b-tabs class="p-5">
                        <b-tab v-for="branch in category.branches" :key="branch.uuid" @click="loadMethods(branch)">
                          <template #title>
                            <div class="d-flex align-items-center">
                              {{ branch.label }}
                            </div>
                          </template>

                          <div class="card">
                            <div class="card-body">
                              <h3>{{ trans('Selectionnez une methode de calcul') }}</h3>
                              <Loading v-if="loadingEngineMethod"/>
                              <!--                            <div class="d-flex" v-else>-->
                              <!--                              <div>-->
                              <!--                                <select class="form-select">-->
                              <!--                                  <option value="" v-for="method in engineMethods" :key="'method_' + method.id">-->
                              <!--                                    {{ method.insurer ? method.insurer.label : 'GLOBAL' }} - {{ method.label }}-->
                              <!--                                  </option>-->
                              <!--                                </select>-->
                              <!--                              </div>-->
                              <!--                              <div class="btn btn-primary btn-icon ms-5">-->
                              <!--                                <i class="fad fa-plus"></i>-->
                              <!--                              </div>-->
                              <!--                            </div>-->
                            </div>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" v-if="hasRole('ROLE_MANAGER')">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-coins fs-2 me-2"></i>
                  Paiements
                </div>
              </template>
              <div>

                <table class="table table-stripped gy-3 gs-3">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Libellé</th>
                    <th>Initiateur</th>
                    <th>Montant</th>
                    <th>
                      Reçu
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>

              </div>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" v-if="hasRole('ROLE_MANAGER')">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-boxes fs-2 me-2"></i>
                  {{ trans('Offres') }}
                </div>
              </template>
              <div>
                <div class="m-5 d-flex justify-content-center">
                  <router-link :to="{ name: 'partner-pack-restriction', params: { code: $route.params.code } }"
                               class="btn btn-primary">
                    <i class="fad fa-edit"></i>
                    Modifier
                  </router-link>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <b-modal :title="trans('Créer un partenaire')" centered id="editPartner" ref="editPartner">
        <form>
          <div class="row" v-if="partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(partner.name)" v-model="partner.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(partner.code)" v-model="partner.code" v-uppercase>
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(partner.contact)" v-model="partner.contact">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{
                  trans('Methode de paiement autorisée')
                }}</label>
              <div class="d-flex flex-wrap  justify-content-between">
                <div v-for="method in methods" :key="'m_' + method.id" class="m-2 fw-bolder d-flex align-items-center">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, partner)"
                           @change="toggleMethodForPartner(method, partner)" class="form-check-input" type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>

            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="savePartner" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
      <b-modal :title="trans('Créer une agence')" centered id="editAgency" ref="editAgency">
        <form>
          <div class="row" v-if="agency && partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Nom') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(agency.name)" v-model="agency.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-light-primary"><strong class="text-primary fw-bolder">{{
                      partner.code
                    }}-</strong></b-input-group-text>
                </template>
                <b-form-input :id="'code'" :state="!helper.empty(agency.code)" v-model="agency.code" v-uppercase>
                </b-form-input>
              </b-input-group>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(agency.contact)" v-model="agency.contact">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{
                  trans('Methode de paiement autorisée')
                }}</label>
              <div class="d-flex flex-wrap  justify-content-between">
                <div v-for="method in methods" :key="'me_' + method.id" class="m-2 fw-bolder d-flex align-items-center">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, agency)"
                           @change="toggleMethodForPartner(method, agency)" class="form-check-input" type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>

            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveAgency" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
      <b-modal :title="trans('Utilisateurs')" centered id="editUser" ref="editUser">
        <form>
          <div class="row" v-if="user && partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Noms') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(user.name)" v-model="user.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Email') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.email)" v-model="user.email">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Agence') }}</label>
              <select v-model="user.code" class="form-control form-select">
                <option :value="partner.code">{{ partner.name }}</option>
                <option :value="agency.code" v-for="agency in partner.agencies" :key="'ag_' + agency.uuid">
                  {{agency.name }}
                </option>
              </select>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Mot de passe') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.password)" v-model="user.password">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.contact)" v-model="user.contact">
              </b-form-input>
            </div>

<!--            <div class="col-auto m-2 flex-grow-1">-->
<!--              <label :for="'code'" class="required form-label">{{ trans('Tenant autorisé') }}</label>-->
<!--              <div class="row">-->
<!--                <div class="col" v-for="(tenant, index) in tenants" :key="index">-->
<!--                  <div class="col-auto m-2 flex-grow-1">-->
<!--                    <label class="form-check form-switch form-check-custom form-check-solid">-->
<!--                      <input class="form-check-input" type="checkbox"-->
<!--                        v-model="user.roles" :value="'ROLE_PARTNER_'+helper.upperCase(tenant.code)"/>-->
<!--                      <span class="form-check-label">-->
<!--                        {{ trans(tenant.label) }}-->
<!--                      </span>-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="updatePartnerUser" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Ref, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Contract from "@/entity/Contract";
import {api} from '@/services/Api';
import Loading from "@/components/Loading.vue";
import FileUpload from "@/components/FileUpload.vue";
import Avenant from "@/entity/Avenant";
import CONSTANTS from "@/Utils/constant";
import Item from "@/entity/Item";
import UserView from "@/components/UserView.vue";

import Property from "@/entity/Property";
import IncorporationDetail from "@/views/Contract/IncorporationDetail.vue";
import Printable from "@/entity/Printable";
import Swal from 'sweetalert2'
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import BranchCategory from "@/entity/BranchCategory";
import Branch from "@/entity/Branch";
import Engine from "@/entity/Engine";
import Insurer from "@/entity/Insurer";
import EngineMethod from "@/entity/EngineMethod";
import {helper} from "@/services/Helper";
import PartnerTree from "@/views/Partner/PartnerTree.vue";
import Survey from "@/entity/Survey";
import VueQr from 'vue-qr'
import Tenant from "@/entity/Tenant";
import User from "@/entity/User";
import Role from "@/entity/Role";
import {shortener} from "@/Utils/Shortener"
import {auth} from '@/services/Auth';

@Component({
  components: {PartnerTree, IncorporationDetail, FileUpload, Loading, Base, UserView, VueQr}
})

export default class PartnerForm extends Vue {


  printUserQrCodes() {

    const container: any = document.querySelector('#user-qr-codes-container')
    const els: any = document.querySelectorAll('body>*')
    els.forEach((el: any) => {
      el.classList.add('print-hide')
    })
    const clone = container.cloneNode(true)
    clone.id = 'qr-clone'
    clone.style.position = 'absolute'
    // container.style.overflow = 'auto'
    clone.style.top = 0
    clone.style.left = 0
    clone.style.width = '100%'
    clone.style.height = '100%'
    clone.style.zIndex = 9999
    document.body.appendChild(clone)
    window.print()

    els.forEach((el: any) => {
      el.classList.remove('print-hide')
    })
    document.body.removeChild(clone)

  }

  shortener = shortener
  helper = helper
  amount = 0
  comparatorUrl = api.comparator
  coreEndpoint = api.core
  uploadRoot = api.uploadRoot
  tabIndex = 0
  isLoading = false
  avenant: any = null
  contracts: Contract[] = []
  methods: PaymentMethod[] = []
  paymentMethods: PaymentMethod[] = []
  partner: Partner | any = null
  user: Partner = new Partner()
  agency: Partner = new Partner()
  branchCategories: BranchCategory[] = []
  surveyOf: any = null
  searchAgency = ''
  // branches: Branch[] = []
  // categories: BranchCategory[] = []
  surveys: Survey[] = []
  tenants: Tenant[] = []
  roleByTenants:string[] = []

  message = "Bonjour j'ai été approché par un apporteur."

  getUrl(data: any) {
    const url = window.location.origin + this.$router.resolve(data).href
    return url
  }


  getUrlPlatform(data: any) {
    const url = window.location.origin + this.$router.resolve(data).href
    return url
  }


  getUrlComparator(data: any) {
    const url = process.env.VUE_APP_COMPARATOR_URL + '?source=' + data
    return url
  }

  getWhaUrl(user: any) {


    let url = null


    if (user) {
      url = process.env.VUE_APP_WHATSAPP_URL + '?text=' + this.message + '\n\n' + '❖' + user.code + '❖'
      return encodeURI(url)
    } else {
      url = process.env.VUE_APP_WHATSAPP_URL + '?text=' + this.message + '\n\n' + '❖' + '' + this.partner.code.toUpperCase() + '❖'
      return encodeURI(url)
    }

    //  return url
  }


  async loadTenants() {
    if (this.tenants.length === 0) {
      this.$store.commit('loading')
      const res = await api.get(api.auth, 'tenant/list')
      if (res && res.data && res.data.tenants) {
        this.tenants = []
        this.roleByTenants = []
        res.data.tenants.forEach((t: any) => {
          const occ = new Tenant(t)
          this.tenants.push(occ)
          this.roleByTenants.push('ROLE_PARTNER_'+occ.code.toUpperCase())
        })



      }
      this.$store.commit('stopLoading')
    }

  }

  getBranchLabelBySlug(slug: string) {
    for (let cat of this.branchCategories) {
      for (let branch of cat.branches) {
        if (branch.slug === slug) {
          return cat.label + ' ▪️ ' + branch.label
          // return  branch.label
        }
      }
    }
    return slug;
  }

  getBranchLogoBySlug(slug: string) {
    // console.log(slug);
    for (let cat of this.branchCategories) {
      for (let branch of cat.branches) {
        if (branch.slug === slug) {
          return api.uploadRoot + branch.photo
        }
      }
    }
    return null
  }

  async loadSurveys() {
    const res = await api.get(api.form, 'api/get/surveys/comparateur')
    if (res && res.data && res.data.surveys) {
      this.surveys = []
      res.data.surveys.forEach((s: Survey) => {
        const occ = new Survey(s)
        this.surveys.push(occ)
      })
      // this.categories = [];
      // res.data.categories.forEach((c: BranchCategory) => {
      //   this.categories.push(new BranchCategory(c))
      // })
      //
      // if (!this.current && this.categories.length > 0) {
      //   this.setCurrent(this.categories[0])
      // }
    }
  }

  async updatePartnerUser() {
    // this.user.code = this.partner.code
    this.$store.commit('loading')
    const res = await api.post(api.auth, 'partner/edit', {partner: this.user});
    this.$store.commit('stopLoading');
    (this.$refs as any).editUser.hide()
    this.loadUsers()

  }


  async deleteUser(code: string) {

    this.user.code = this.partner.code

    const reset = await Swal.fire({
      title: 'Suppression',
      icon: 'question',
      html: 'Êtes-vous sûr de vouloir supprimer ce partenaire ?',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
          'Supprimer',
      confirmButtonAriaLabel: 'Supprimer',
      cancelButtonText:
          'Annuler',
      cancelButtonAriaLabel: 'Annuler'
    })
    if (reset.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.delete(api.auth, 'partner/block/' + code);
      this.$store.commit('stopLoading');
      this.loadUsers()
    }
  }


  async mounted() {
    this.loadPartner()
    this.loadContracts()
    this.loadUsers()
    this.loadPaymentMethods()
    this.loadTenants()
    this.branchCategories = await this.$store.getters.branchCategories()
    // await this.loadContract()
  }

  async loadPaymentMethods() {
    // this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/method/list')
    if (res && res.data) {
      this.methods = []
      res.data.methods.forEach((p: PaymentMethod) => {
        this.methods.push(new PaymentMethod(p))
      })
    }
    // this.$store.commit('stopLoading')
  }

  async loadPayments() {
    const res = await api.get(api.core, 'payment/')
  }

  async deletePartner(partner: Partner) {
    const response = await Swal.fire({
      title: 'Supprimer ce partenaire',
      html: "Toutes les informations liées à ce partenaire seront supprimées",
      timer: 10000,
      icon: "error",
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      const res = await api.get(api.core, 'partner/delete/' + partner.uuid)
      if (res && res.data) {
        this.loadPartner()
        this.loadContracts()
      }
    }
  }

  createSub(partner: Partner) {
    // console.log(code)
    this.createAgency(partner)
    const refs = this.$refs as any;
    if (refs.editAgency) {
      refs.editAgency.show()
    }
  }

  createAgency(partner: Partner | null = null) {
    this.agency = new Partner()
    if (partner) {
      this.agency.parent = partner.id as any
    }

  }

  // createAgency() {
  //   this.agency = new Partner()
  //   this.agency.parent = this.partner
  //
  // }

  editAgency(agency: Partner) {

    console.log(agency);

    this.agency = new Partner({...agency});
    (this.$refs as any).editAgency.show()
    this.agency.parent = this.partner
    if (this.agency.code.includes(this.partner.code)) {
      this.agency.code = this.agency.code.replace(this.partner.code, '')
    }
    this.agency.code = helper.slugify(this.agency.code, '')

  }

  initCommission() {
    if (this.branchCategories.length > 0) {
      if (this.branchCategories[0].branches.length > 0) {
        this.loadMethods(this.branchCategories[0].branches[0])
      }
    }
  }

  toggleMethodForPartner(method: PaymentMethod, partner: Partner) {
    const index = partner.paymentMethods.findIndex((p: PaymentMethod) => p.id === method.id)
    console.log(index)
    if (index >= 0) {
      partner.paymentMethods.splice(index, 1)
    } else {
      partner.paymentMethods.push(method)
    }
  }

  async savePartner() {
    this.$store.commit('loading')
    let partner = {...this.partner};
    delete partner.agencies
    console.log(partner)
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'partner/edit', {partner})
    // return
    this.$store.commit('stopLoading')
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editPartner) {
        refs.editPartner.hide()
      }
      this.loadPartner()
    }
  }

  async saveAgency() {
    this.$store.commit('loading')
    let agency: any = {...this.agency};
    agency.parent = this.partner.id;
    delete agency.agencies
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'partner/edit', {partner: agency})
    // return
    this.$store.commit('stopLoading')
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editAgency) {
        refs.editAgency.hide()
      }
      this.loadPartner()
    }
  }

  editUser(user: Partner) {
    this.user = new Partner(user);
    (this.$refs as any).editUser.show()
  }

  createUser() {
    this.user = new Partner();
    this.user.code = this.partner.code;
    (this.$refs as any).editUser.show()
  }

  isMethodActiveForPartner(method: PaymentMethod, partner: Partner) {
    return partner.paymentMethods.find((p: PaymentMethod) => p.id === method.id) ? true : false
  }

  async loadPartner() {
    this.isLoading = true
    const res = await api.get(api.core, 'partner/get/' + this.$route.params.code)
    this.isLoading = false
    if (res && res.data) {
      this.partner = new Partner(res.data.partner)

    }
  }

  async toggleTenant(tenant: Tenant) {
    if (this.partner && this.partner.id) {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'partner/' + this.partner.id + '/toggle/tenant/' + tenant.code)
      if (res && res.data && res.data.partner) {
        this.partner = new Partner(res.data.partner)
      }
      this.$store.commit('stopLoading')
    }
  }


  toggleRole(data: any) {
    const role: any = data.role
    const tenant: Tenant = data.tenant
    // console.log(data)
    let code = role.code.startsWith('ROLE_') ? role.code : 'ROLE_' + role.code
    if (tenant) {
      code += '_' + tenant.code.toUpperCase()
    }
    const action = this.partner.roles.includes(code) ? 'remove' : 'append'
    const list = role.getlist()

    if (role instanceof Role) {
      list.forEach((r: string) => {
        if (tenant) {
          r += '_' + tenant.code.toUpperCase()
        }
        // console.log(r)
        const index = this.partner.roles.indexOf(r)
        if (index !== -1) {
          this.partner.roles.splice(index, 1)
        }
      })
    }

    if (action === 'remove') {
      // list.forEach((r: string) => {
      const index = this.partner.roles.indexOf(code)
      if (index !== -1) {
        this.partner.roles.splice(index, 1)
      }
      // })
    } else {
      this.partner.roles.push(code)
      list.forEach((r: string) => {
        if (tenant) {
          r += '_' + tenant.code.toUpperCase()
        }
        const index = this.partner.roles.indexOf(r)
        if (index === -1) {
          this.partner.roles.push(r)
        }
      })
    }
    // console.log('toggle')
    console.log(this.partner.roles.join(','))
    // console.log(role)
  }

  async loadPartnerMethods() {
    // this.$store.commit('loading')
    const res = await api.get(api.core, 'partner/method/list/' + this.$route.params.code)
    if (res && res.data) {
      this.methods = []
      res.data.methods.forEach((p: PaymentMethod) => {
        this.methods.push(new PaymentMethod(p))
      })
    }
    // this.$store.commit('stopLoading')
  }


  loadingContracts = false
  contractPage = 1

  async loadContracts() {
    this.loadingContracts = true
    const res = await api.post(api.core, 'contract/search', {
      searched: {
        partner: this.$route.params.code,
        page: this.contractPage,
        perPage: 10,
        branch: null,
        customer: null,
        insurer: null,
        manager: null,
        police: null,
        effetMin: null,
        effetMax: null,
        expireMin: null,
        expireMax: null,
        createMin: null,
        createMax: null,
      }
    })
    if (res && res.data) {
      this.contracts = []
      res.data.contracts.forEach((c: any) => {
        const occ = new Contract(c)
        this.contracts.push(occ)
      })
    }
    this.loadingContracts = false
  }

  getAgencyNameByCode(code: string) {
    if (this.partner) {
      const agency = this.partner.agencies.find((agency: Partner) => {
        return agency.code === code
      })
      return agency ? agency.name : code
    }
  }

  users: Partner[] = []
  loadingUsers = false

  async loadUsers() {
    this.loadingUsers = true
    const res = await api.get(api.auth, 'partner/user/list/' + this.$route.params.code)
    this.loadingUsers = false

    if (res && res.data && res.data.users) {
      this.users = []
      res.data.users.forEach((p: any) => {
        const occ = new Partner(p)
        this.users.push(occ)
      })
    }
  }


  agencies: Partner[] = []

  async loadAgencies() {
    this.loadingUsers = true
    const res = await api.get(api.core, 'partner/agencies/list/' + this.$route.params.code)
    if (res && res.data && res.data.agencies) {
      this.agencies = []
      res.data.agencies.forEach((p: any) => {
        const occ = new Partner(p)
        this.agencies.push(occ)
      })
    }
    this.loadingUsers = false
  }

  engineMethods: EngineMethod[] = []
  loadingEngineMethod = false

  async loadMethods(branch: Branch) {
    this.loadingEngineMethod = true
    const res = await api.get(api.core, 'engine/method/list/global/' + branch.slug + '/version/' + branch.engineVersion)
    if (res && res.data) {
      this.engineMethods = []
      res.data.methods.forEach((m: EngineMethod) => {
        const occ = new EngineMethod(m)
        this.engineMethods.push(occ)
      })

      // this.engines = []
      // res.data.engines.forEach((e: Engine) => {
      //     const occ = new Engine(e)
      //     this.engines.push(occ)
      // })
      // console.log(this.branch)
    }
    this.loadingEngineMethod = false
  }
}
</script>
<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .2s;
}

.slide-left-leave-from {
  transition: all .2s;
}

.slide-left-enter {
  opacity: 0;
  transform: translateY(50%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.modal-xl .modal-content {
  height: 100%;
}

.badge-design {
  padding: 0rem !important;
  background: white;
  width: 40rem;
}

.badge-design > img {
  padding: 0rem !important;
  object-fit: cover;
  width: 100%;
}

.badge-design .content-qr {
  background: url('../../../assets/logo-trans.svg') no-repeat center;


  background-size: cover;
  padding: 5rem;
}
</style>
