<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'crm-index' }" class="text-muted text-hover-primary">
                  <i class="fad fa-users"></i>
                  {{ trans("Liste des prospects") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>

        <div class="d-flex align-items-center py-1">
          <button @click="EditCustomer()" class="btn btn-primary btn-sm">
            <i class="fad fa-user"></i>
            {{ trans("Ajouter un prospect") }}
          </button>

          <b-modal @ok="saveClient" v-if="customer" centered id="editProspect" title="Ajouter un prospect">
            <div class="mb-3">
              <label for="name" class="form-label">Nom complet</label>
              <input type="text" name="name" v-model="customer.name" class="form-control" id="name" />
            </div>

            <!--
              <div class="mb-3">
                <label for="name" class="form-label">Genre</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="gender"
                      id="male"
                    />
                    <label class="form-check-label" for="male"> Homme </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="gender"
                      id="female"
                      checked
                    />
                    <label class="form-check-label" for="female"> Femme </label>
                  </div>
                </div>
              </div>
              -->

            <div class="row justify-content-center align-items-center g-2 mb-3">
              <div class="col">
                <label for="name" class="form-label">Numero de telephone</label>
                <input type="tel" name="name" v-model="customer.mobile" class="form-control" id="name" />
              </div>
            </div>

            
              
              <div class="my-3 w-100">
              <div class="fw-bolder my-3">Type de client</div>
              <div class="d-flex container-check w-100" label="">
                <div v-for="(item, index) in statusClient" :key="index" class="choiceContent">
                  <input :value="item.value" class="hidden" type="radio" v-model="customer.status" name="statusClient"
                    :id="'statusClient' + index" />
                  <label style="font-size: 2.5rem;" :class="index == statusClient.length - 1
                    ? ''
                    : 'border-end-0 '
                    " class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                    :for="'statusClient' + index">
                    {{ item.text }}
                  </label>
                </div>
              </div>
            </div>

              

                <!--
            <div>
           
                      <AutoComplete v-model="customer.manager" :options="usermaped" label="Assigner le prospsect" />
                    </div>

                  
                <div class="col">
                  <label for="name" class="form-label">Localisation</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                  />
                </div>
              </div>

              <div class="mb-3">
                <label for="dateBirthday" class="form-label"
                  >Date de naissance</label
                >
                <input
                  type="date"
                  name="dateBirthday"
                  class="form-control"
                  id="dateBirthday"
                />
              </div>
            <div class="border my-5" />

          


 -->

 <div>
              <h5 class="text-center">Informations supplementaires</h5>

              <div v-for="(item, index) in customer.additionnalInfo" :key="index">
                <select class="form-select form-select-sm" v-model="item.type">
                  <option v-for="(option, index) in optionsInfo" :value="option.value" :key="index">
                    {{ option.text }}
                  </option>
                </select>
                <b-input-group class="my-2">
                  <b-form-input v-model="item.label" placeholder="Libelé"></b-form-input>
                  <b-form-input v-if="item.type != 'file'" v-model="item.value"></b-form-input>
                  <div v-else class="mx-3">
                    <FileUpload v-model="item.value" />
                  </div>

                  <b-input-group-append>
                    <div @click="removeInfo(index)" style="
                          border-top-left-radius: 0 !important;
                          border-bottom-left-radius: 0 !important ;
                        " class="btn btn-sm btn-icon btn-danger rounded-end">
                      <i class="fad fa-times"></i>
                    </div>
                  </b-input-group-append>
                </b-input-group>
              </div>

              </div>
            <div @click="addInfo"
              class="border-dashed border border-primary btn w-100 mt-3 text-primary fw-bolder text-hover-white bg-hover-primary">
              Ajouter une information
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:content>
    <div class="card">
      <div class="card-body py-3 d-flex justify-content-between flex-column align-items-end">
        <div class="d-flex align-items-center">
          <button @click="resetSearch" class="btn btn-icon btn-light-danger btn-xs me-3 justify-content-center"
            type="reset">
            <i class="fad fa-trash fw-bolder cursor-pointer"></i>
          </button>
          <b-dropdown no-caret no-flip ref="cotationFilter" right size="sm" style="padding: 0" tag="div"
            toggle-class="topbar-item text-decoration-none" variant="link">
            <template v-slot:button-content>
              <div class="cursor-pointer btn btn-secondary btn-sm me-3">
                <i class="fad fa-search me-2"></i>
                <template>Rechercher</template>
              </div>
            </template>

            <b-dropdown-text class="min-w-650px" tag="div">
              <div class="row">
                <div class="col">
                  <label class="my-4 fw-bolder" for="">{{
                  trans("De")
                }}</label>
                  <input class="form-control" type="date" v-model="dataSearch.after" />
                </div>
                <div class="col">
                  <label class="my-4 fw-bolder" for="">{{
                  trans("à")
                }}</label>
                  <input class="form-control" type="date" v-model="dataSearch.before" />
                </div>
              </div>

              <div class="my-3">
                <VueMultiselect v-if="partners" :options="partners" :selectedLabel="'Ok'"
                  :tagPlaceholder="trans('Selectionner un partenaire')" placeholder="Selectionner un partenaire"
                  select-label="+" :value="dataSearch.partner" @input="setPartner" :customLabel="customPartnerLabel"
                  tag-placeholder="+" track-by="uuid">
                </VueMultiselect>
              </div>

              <div>
                <div class="col-auto flex-grow-1 mt-3">
                  <label class="fw-bolder" for="">{{ trans("Type") }}</label>
                  <div class="d-flex flex-wrap flex-grow-1">
                    <label class="d-flex align-items-center cursor-pointer text-primary m-2"
                      v-for="option in optionType">
                      <div class="form-check form-check-custom form-check-sm">
                        <!-- <input type="checkbox" class="form-check-input"> -->
                        <input :id="option.value" type="checkbox" class="form-check-input" v-model="dataSearch.status"
                          :true-value="[]" :value="option.value" />
                      </div>
                      <div class="ms-2" style="font-size: 3rem">
                        {{ option.text }}
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end my-5">
                <button @click="resetSearch" class="btn btn-sm btn-white btn-active-light-primary me-2" type="reset">
                  Réinitialiser
                </button>
                <button @click="search" class="btn btn-sm btn-primary" type="submit">
                  Rechercher
                </button>
              </div>
            </b-dropdown-text>
          </b-dropdown>
        </div>

        <div class="w-100">
          <Pagination :count="total" :itemLength="prospects.length" :matchCount="total" v-model="params.page"
            showEmpty="true" class="p-10" :perPage="params.limit" :key="'pagination_' + params.page" @input="load()">
          </Pagination>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr class="fw-bolder text-muted">
                <th class="w-25px">
                  <div class="form-check form-check-sm form-check-custom form-check-solid">
                    <input class="form-check-input" data-kt-check="true" data-kt-check-target=".widget-9-check"
                      type="checkbox" value="1" />
                  </div>
                </th>
                <th>{{ trans("Prospect") }}</th>
                <th>{{ trans("Type") }}</th>
                <th>{{ trans("Contact") }}</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="prospect in prospects">
                <td></td>
                <td style="font-weight: bold; text-transform: uppercase">
                  <div>{{ prospect.name }}</div>
                </td>

                <td style="font-size: 3rem">
                  <!-- <i class="fad fa-check-double text-success fs-2" v-if="prospect.isVerified"></i> -->

                  <p>
                    {{
                  prospect?.status == "HOT"
                    ? "😍"
                    : prospect?.status == "WARM"
                      ? "😑"
                      : prospect?.status == "COLD"
                        ? "🥶"
                        : prospect?.status == "UNVALID"
                          ? "😒"
                          : "🫥"
                }}


                  </p>

                  <!--  <i class="fad fa-times-circle text-danger fs-2" v-else></i> -->
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <template v-if="!empty(prospect.mobile)">
                      <div class="d-flex flex-wrap">
                        <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2">
                          {{ helper.phoneFormat(prospect.mobile) }}
                          <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                            <i class="fad fa-phone"></i>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!--
                    <template v-if="!empty(prospect.email)">
                      <span class="fw-bolder text-gray-400 mt-1">
                        {{ prospect?.email }}
                      </span>
                    </template>
                 

                  -->
                  </div>
                </td>

                <!--
               
                  <router-link class="me-2" :to="{
                  name: 'details-clients',
                  params: { id: prospect.id },
                }">
 </router-link>

                
                   -->
                <td class="">

                  <router-link class="me-2" :to="{
                  name: 'details-clients',
                  params: { id: prospect.id },
                }">
                    <button class="btn btn-sm btn-icon btn-primary">
                      <i class="fad fa-eye"></i>
                    </button>

                  </router-link>



                  <router-link class="mx-2" :to="{
                  name: 'handshake-form',
                  params: { clientId: prospect?.id, uuid: 'null' },
                }">
                    <button class="btn btn-sm btn-icon btn-success">
                      <i class="fad fa-phone"></i>
                    </button>
                  </router-link>

                  <button class="btn btn-sm btn-icon btn-warning mx-2" @click="ProgramHandshake(prospect.id)">
                    <i class="fad fa-calendar"></i>
                  </button>

                  <b-modal :id="'showProgramModal' + prospect.id" centered title="Programmer une prise de contact">
                    <div>
                      <AutoComplete v-model="value" :options="usermaped" label="Assigner la prise de contact" />
                    </div>

                    <div class="mb-3">
                      <label for="title-handshake" class="form-label">{{
                  trans("Date de la prise de contact")
                }}</label>
                      <input type="datetime-local" class="form-control" id="title-handshake" />
                    </div>

                    <label for="title-handshake" class="form-label">{{
                    trans("Type")
                  }}</label>

                    <div class="d-flex rounded-1 overflow-hidden">
                      <div v-for="(item, index) in typeContact" :key="index" class="">
                        <input :value="item.value" class="hidden" type="radio" name="typeNextContact"
                          :id="'typeNextContact' + index" />
                        <label :class="index == typeContact.length - 1
                  ? 'rounded-end'
                  : 'border-end-0 rounded-start'
                  " class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                          :for="'typeNextContact' + index">
                          {{ item.text }}
                        </label>
                      </div>
                    </div>
                  </b-modal>

                  <button class="btn btn-sm btn-icon btn-secondary mx-2" @click="EditCustomer(prospect)">
                    <i class="fad fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  </Base>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import FileUpload from "@/components/FileUpload.vue";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import Popup from "@/entity/Popup";
import AutoComplete from "@/components/AutoComplete.vue";
import User from "@/entity/User";
import Partner from "@/entity/Partner";

import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import SellAdditionalInfos from "@/entity/SellAdditionalInfos";
import UniqProspect from "@/entity/UniqProspect";
import Pagination from "@/components/Pagination.vue";
import AdditionalInfo from '../../entity/AdditionalInfo';

@Component({
  components: { Base, AutoComplete, FileUpload, VueMultiselect, Pagination },
})
export default class ClientsView extends Vue {
  prospects: UniqProspect[] = [];
  total: number = 0;
  params: any = {
    limit: 20,
    page: 1,
  };
  value = "";
  partners: Partner[] = [];
  users: User[] = [];
  user = new User();
  usermaped: any[] = [];
  searchTemplate = {
    branch: null,
    after: null,
    before: null,
    contains: null,
    minimalState: 0,
    hasClientInfos: false,
    manager: null,
    partner: null,
    page: 1,
    max: 100,
    statut: ["UNKNOW"] as any,
  };

  typeContact = [
    {
      text: "Rendez-vous",
      value: "rendezvous",
    },
    {
      text: "A distance",
      value: "a_distance",
    },
  ];

  customer?: UniqProspect = new UniqProspect();

  resetSearch() {
    this.dataSearch = {
      partner: null,
      after: null,
      before: null,
      status: [],
    };

    this.load();
  }

  setPartner(e: any) {
    this.dataSearch.partner = e.code;
    this.$forceUpdate();
  }

  infoSup = [] as any;

  optionsInfo = [
    {
      value: "text",
      text: "Informations",
    },
    {
      value: "file",
      text: "Fichiers",
    },
  ];

  optionType = [
    {
      text: "🥶",
      value: "COLD",
    },
    {
      text: "😒",
      value: "UNVALID",
    },
    {
      text: "🥵",
      value: "HOT",
    },
    {
      text: "🫥",
      value: "NEW",
    },
    {
      text: "😑",
      value: "WARM",
    },
  ];

  addInfo() {
    const additionnaInfo = new AdditionalInfo();
    this.customer?.additionnalInfo.push(additionnaInfo);

    this.$forceUpdate();
  }

  customPartnerLabel(option: any) {
    // console.log(option)
    if (typeof option === "string") {
      return option;
    }
    return option.code;
  }

  removeInfo(item: number) {
    this.customer?.additionnalInfo.splice(item, 1);
    this.$forceUpdate();
  }

  async loadUsers() {
    const res = await api.get(api.auth, "user/list");
    if (res && res.data) {
      this.users = [];
      res.data.users.forEach((u: User) => {
        const occ = new User(u);
        this.users.push(occ);
      });

      this.usermaped = this.users.map((item: User) => ({
        name: item.name + " " + item.prename,
        value: item.email,
      }));
    }
  }

  async saveClient() {
    const pop = new Popup(
      "Chargement",
      "Enregistrement du prospect",
      "primary",
      "fad fa-sync",
      true
    );


    try {
      const res = await api.post(api.form, "prospect/edit", 
        this.customer
      );

      if (res.status == 200) {
        pop.hide();
        this.load();
      }
    } catch (error) {
      pop.hide();
    }
  }

  mounted() {
    this.load();
    this.loadUsers();
    this.loadPartners();
  }

  

  ProgramHandshake(idCustomer: number) {
    this.$bvModal.show("showProgramModal" + idCustomer);

    this.$forceUpdate;
  }

  prospectShowed = new UniqProspect();
  ViewInformationsProspect(prospect: UniqProspect) {
    this.$bvModal.show("showViewProspectModal" + prospect.id);

    this.prospectShowed = prospect;

    this.$forceUpdate;
  }

  EditCustomer(customer?: UniqProspect) {
    this.$bvModal.show("editProspect");
    if (customer) {
      this.customer = customer;
    } else {
      console.log("object");
      this.customer = new UniqProspect();
    }
  }

  idEditModal(item?: number) {
    if (item) {
      return "editProspect" + item;
    } else {
      return "editProspect";
    }
  }

  async load() {
    const pop = new Popup(
      "Chargement",
      "Liste des prospect",
      "primary",
      "fad fa-sync",
      true
    );
    const res = await api.get(
      api.form,
      `prospect/list?limit=${this.params.limit}&page=${this.params.page}`
    );
    if (res && res.data) {
      this.total = res.data.total;
      this.prospects = [];
      res.data.prospects.forEach((p: any) => {
        const occ = new UniqProspect(p);
        this.prospects.push(occ);
      });

      pop.hide();
    }
  }

  statusClient = [
    {
      text: "😒",
      value: "UNVALID",
    },
    {
      text: "🥶",
      value: "COLD",
    },

    {
      text: "😑",
      value: "WARM",
    },
    {
      text: "😍",
      value: "HOT",
    },
  ];

  dataSearch = {
    partner: null,
    after: null,
    before: null,
    status: [],
  };

  async search() {
    // console.log(this.dataSearch);
    const pop = new Popup(
      "Chargement",
      "Recherche des prospect",
      "primary",
      "fad fa-sync",
      true
    );
    const search = { search: this.dataSearch };
    const res = await api.post(api.sell, "customer/search", search);
    if (res && res.data) {
      this.prospects = [];
      res.data.customers.forEach((c: any) => {
        const occ = new UniqProspect(c);
        this.prospects.push(occ);
      });
      pop.hide();
    } else {
      pop.hide();
    }
  }

  optionsPartner = [];

  async loadPartners() {
    this.$store.commit("loading");
    const res = await api.get(api.core, "partner/top/list");
    if (res && res.data) {
      this.partners = [];

      res.data.partners.forEach((p: Partner) => {
        this.partners.push(new Partner(p));
      });
    }
    this.$store.commit("stopLoading");
  }
}
</script>
<style scoped></style>
